import { DomEvent } from "leaflet";
import { useRef, useEffect } from "react";
import { Card } from "react-bootstrap";

export function MapInfoBoxContainer({ children, className }: { children: React.ReactNode, className?: string }): JSX.Element {
  // Prevents clicks on this element from propagating to the map
  const divContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (divContainer.current) {
      DomEvent.disableClickPropagation(divContainer.current);
      DomEvent.disableScrollPropagation(divContainer.current);
    }
  });

  return (
    <div className={className || 'leaflet-top leaflet-left'} ref={divContainer}>
      {children}
    </div>
  );
}

export function MapInfoBox({ children, style = { opacity: .8 } }: { children: React.ReactNode, style?: React.CSSProperties }): JSX.Element {
  return (
    <Card className='leaflet-control leaflet-bar' style={style}>
      <Card.Body style={{ width: '100%' }}>
        {children}
      </Card.Body>
    </Card>
  );
}