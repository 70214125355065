import { ApiClient } from './ApiClient';

const WFS_PATH = 'geoserver/geonode/wfs'


export class WFSClient {
  apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this.apiClient = apiClient;
  }

  async queryWFS(type: string, filter: string): Promise<any> {
    const params = {
      'service': 'WFS',
      'version': '1.0.0',  // TODO: ONLY WORKS WITH VERSION 1.0.0. WHYYYYYYYYY
      'request': 'GetFeature',
      'typeNames': type,
      'count': '50',
      'outputFormat': 'application/json',
      'CQL_FILTER': filter,
      'srsName': 'EPSG:4326'
    }

    return (await this.apiClient.get(WFS_PATH, params)).data;
  }
}
